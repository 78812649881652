import $ from 'jquery';
import './polyfills.js';

class customAddToCart {

	constructor(type, productImage = false) {
		this.type = type;
		this.productImage = productImage;
		this.doingAjax = false;
	}

	addToCart(e, button) {
		e.preventDefault();

		if (!this.doingAjax) {
			button.classList.remove('added');
			button.classList.add('loading');
			this.doingAjax = true;

			let form = button.closest('form.cart');
			let formData = new FormData(form);

			formData.append('action', 'customAddToCart');
			formData.append('addType', this.type);

			if (this.type == 'configurator') {
				let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)) ? 1 : 0;
				formData.append('configurator_product', this.dataURItoBlob(this.productImage), `configuratorProduct-${Date.now()}.jpg`);
				formData.append('is_safari', isSafari);
			}

			$.ajax({
				method: 'POST',
				url: wc_add_to_cart_params.ajax_url,
				data: formData,
				type: 'POST',
				processData: false,
				contentType: false,
				success: response => {
					let event = new Event('wc_fragment_refresh');

				    document.body.dispatchEvent(event);
				    button.classList.remove('loading');
					button.classList.add('added');
				    this.addedProductPreview();
				}
			});
		}
	}

	addedProductPreview() {
		$.ajax({
			method: 'POST',
			url: wc_add_to_cart_params.ajax_url,
			data: {
				action: 'addedProductPreview'
			},
			success: response => {
				let $responseHTML = $(JSON.parse(response));

				$responseHTML.find('.popup-overlay, .close').on('click', (e) => {
					e.preventDefault();
					$('.basket-product-added-container').remove();
				});
			    $(document.body).append($responseHTML);
			    this.doingAjax = false;
			}
		});
	}

	dataURItoBlob(dataURI) {

	    let byteString;
	    if (dataURI.split(',')[0].indexOf('base64') >= 0)
	        byteString = atob(dataURI.split(',')[1]);
	    else
	        byteString = unescape(dataURI.split(',')[1]);

	    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	    let ia = new Uint8Array(byteString.length);
	    for (let i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }

	    return new Blob([ia], {type:mimeString});
	}

}

export default customAddToCart;