// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
	home: $(".home-slider .inner"),
	sales: $(".actual-sales-container .slider.products")
};

if (sliders.home.length) {

	sliders.home.on('init', (slick) => {
		const slider = document.getElementById('home-slider');
		const initialDot = slider.querySelector('.dot.initial');
		//const slidePlaySpeed = sliders.home.slickGetOption('autoplaySpeed');
		//const slideSpeed = sliders.home.slickGetOption('speed');

		if (initialDot) {
			//initialDot.querySelector('circle').style.transition = `all ${slidePlaySpeed/1000}s linear`;
			initialDot.classList.remove('initial');

			//setTimeout(() => { initialDot.querySelector('circle').style.transition = `all ${(slidePlaySpeed + slideSpeed)/1000}s linear`; }, slidePlaySpeed);
		}
	});

	sliders.home.slick({
		dots: true,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		fade: true,
		speed: 1000,
		pauseOnHover: false,
		pauseOnFocus: false,
		prevArrow: $('.home-slider-prev'),
		nextArrow: $('.home-slider-next'),
		customPaging: (slider, i) => {

			const slidePlaySpeed = slider.slickGetOption('autoplaySpeed');
			const slideSpeed = slider.slickGetOption('speed');
			const transition = (slidePlaySpeed + slideSpeed)/1000;
			const dot = document.createElement('div');

		    dot.classList.add('dot');
		    if (i == 0) {
		    	dot.classList.add('initial');
		    }
		    dot.innerHTML = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 46 46" xml:space="preserve"> <circle id="XMLID_160_" class="circle" cx="23" cy="23" r="20"></circle></svg>`;
			dot.querySelector('circle').style.transition = `all ${transition}s linear`;

		    return dot;
		},
		appendDots: $('.home-slider-dots')
	});
}

$(document).on('vc_js', function(){
	if (sliders.sales.length) {
		sliders.sales.slick({
			dots: false,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			slidesToShow: 3,
			nextArrow: $('.sales-slider-next'),
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}
});