import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu, toggleLanguages } from "./navigation";
import FloatLabels from "./forms/float-labels";
import customAddToCart from "./custom-add-to-cart";
import testimonialForm from "./forms/add-testimonial-form";

window.addEventListener("load", () => {
    new SiteHeader();
    new FloatLabels();

    toggleMenu();
    toggleLanguages();

    if (isMobile()) {
        closeMenu();
        addSticky();
    }

    const videoPlayer = document.getElementById('home-video');

    if (videoPlayer) {
        document.getElementById('video-play').addEventListener('click', function(e){
            e.preventDefault();
            if (videoPlayer.nodeName == "VIDEO") {
                videoPlayer.play();
            } else {
                videoPlayer.src += "&autoplay=1";
            }
            document.querySelector('.video-placeholder').classList.add('hidden');
        });
    }

    const addToCartSimple = document.querySelectorAll('.button.simple-add-to-cart');

    if (addToCartSimple.length) {
        addToCartSimple.forEach(button => {
            button.addEventListener('click', e => {
                let instance = new customAddToCart('simple');
                instance.addToCart(e, button);
            });
        });
    }

    const addTestimonialButton = document.querySelectorAll('.add-testimonial-button');

    if (addTestimonialButton.length) {
        addTestimonialButton.forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault();
                new testimonialForm();
            });
        });
    }
});

window.onresize = () => {
    if (isMobile()) {
        closeMenu();
        addSticky();
    }
};
