//js
//require("babel-polyfill");
require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/animations/SiteAnimations");
require("./js/main");

//fonts
// require('./fonts/font/stylesheet.css');

// css
import "normalize.css";
require("./sass/style.scss");
