import $ from 'jquery';

class testimonialForm {

	constructor() {
		this.formContainer = document.getElementById('add-testimonial');
		this.form = this.formContainer.querySelector('#testimonial-form');
		this.closeButtons = this.formContainer.querySelectorAll('.close');
		this.doingAjax = false;
		this.init();
		this.addEvents();
	}

	init() {
		this.formContainer.classList.add('show');
	}

	addEvents() {
		this.closeButtons.forEach(button => button.addEventListener('click', (e) => {
			e.preventDefault();
			this.closeForm();
		}));
		this.form.addEventListener('submit', e => this.addTestimonial(e));
	}

	addTestimonial(e) {
		e.preventDefault();

		if (!this.doingAjax) {
			this.doingAjax = true;

			let formData = new FormData(this.form);

			formData.append('action', 'addTestimonial');

			$.ajax({
				method: 'POST',
				url: wc_add_to_cart_params.ajax_url,
				data: formData,
				type: 'POST',
				processData: false,
				contentType: false,
				success: response => {
					this.doingAjax = false;
					this.formContainer.classList.add('testimonial-added');
					this.clearFields();
					setTimeout(() => this.closeForm(), 5000);
				}
			});
		}
	}

	clearFields() {
		let allInputs = this.formContainer.querySelectorAll('.main input, .main textarea');
		allInputs.forEach(input => {
			input.value = '';
		});
	}

	closeForm() {
		this.formContainer.classList.remove('show', 'testimonial-added');
	}

}

export default testimonialForm;