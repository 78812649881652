//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

let smoothScroll = new SmoothScroll('a[href*="#"]', {
	ignore: "[data-vc-container], .languages a, .enable-popup, .close, .popup-overlay, .add-testimonial-button",
	header: '#masthead',
	offset: 50
});

document.addEventListener('scrollStop', e => {
	const anchorID = e.detail.anchor.getAttribute('id');
	
	if (anchorID == 'film') {
		let event = new Event('click');
		document.getElementById('video-play').dispatchEvent(event);
	}
});

window.addEventListener("load", () => {

	const hash = window.location.hash;
	const anchorSection = hash ? document.querySelector(hash) : null;

	if (anchorSection) {
		smoothScroll.animateScroll(anchorSection);
	}
});