import $ from "jquery";
import { isMobile } from "../helper";
import CoreAnimation from "./CoreAnimation";
import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min.js";

class SiteAnimations {

	constructor() {
		this.socials = document.querySelector('.social-actions-links');
		this.AnchorSections = document.querySelectorAll('.anchor-section');
		this.animationController = new ScrollMagic.Controller();
		this.toggleItems = document.querySelectorAll('.toggle-item');
		this.init();
	}

	init() {
		if (!isMobile() && this.socials) { this.animateStickySocials(); }
		if (!isMobile() && this.AnchorSections.length) { this.animateAnchorSections(); }
		if (this.toggleItems.length) { this.toggleDescriptionItems(); }
	}

	toggleDescriptionItems() {
		this.toggleItems.forEach(item => {
			item.addEventListener('click', function() {
				let description = this.querySelector('.toggle-description');

				this.classList.toggle('toggled');
				CoreAnimation.slideToggle(description);
			});
		});
	}

	animateStickySocials() {
		let container = document.querySelector('.article-wrapper');
		let containerHeight = container.offsetHeight;
		let socialsHeight = this.socials.clientHeight;
		let header = document.getElementById('masthead');
		let offset = -header.offsetHeight-50;
		let scene = new ScrollMagic.Scene({triggerElement: container, duration: containerHeight, offset: offset, triggerHook: 0});

		scene.addTo(this.animationController);
		scene.on('progress', e => {
			if ((e.progress * containerHeight) < (containerHeight - socialsHeight)) {
				this.socials.style.transform =  `translateY(${Math.round(e.progress*containerHeight)}px)`;
			}
		});
	}

	animateAnchorSections() {
		let header = document.getElementById('masthead');
		let offset = -header.offsetHeight-100;
		const anchorMenu = document.getElementById('anchor-menu');
		
		this.AnchorSections.forEach(section => {
			let scene = new ScrollMagic.Scene({triggerElement: section, duration: section.offsetHeight, offset: offset, triggerHook: 0});
			
			scene.addTo(this.animationController);
			scene.on('enter leave', (e) => {
				const anchorID = section.id;
				const currentAnchor = anchorMenu.querySelector(`.${anchorID}-anchor`);

				if (currentAnchor) {
					anchorMenu.querySelector('.anchor-element.active').classList.remove('active');
					currentAnchor.classList.add('active');
					
					if (e.type == 'enter') {
						currentAnchor.classList.add('show-title');
						setTimeout(() => currentAnchor.classList.remove('show-title'), 700);
					}
				}
			});
		});
	}

}

window.addEventListener("load", () => new SiteAnimations());